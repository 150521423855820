var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-row',{staticClass:"mb-24",attrs:{"gutter":24}},[_c('a-col',{staticClass:"mb-24",attrs:{"span":12,"md":8}},[_c('a-card',{staticClass:"header-solid h-full card-profile-information",attrs:{"bordered":false,"bodyStyle":{ paddingTop: 0, paddingBottom: '16px' },"headStyle":{ paddingRight: 0 }},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('a-row',[_c('a-col',{attrs:{"span":12}},[_c('h6',{staticClass:"font-semibold m-0 my-4"},[_vm._v("Paramètre de prêt")])]),_c('a-col',{attrs:{"span":12}},[_c('router-link',{attrs:{"to":{ name: 'Pret_type' }}},[_c('a-button',{attrs:{"type":"primary"}},[_vm._v(" Créer type de pret ")])],1)],1)],1)]},proxy:true}])},[_c('a-descriptions',{attrs:{"title":_vm.Parametre,"column":1}},[_c('a-descriptions-item',{attrs:{"label":"Taux d'interet prêt"}},[_vm._v(" 30% ")]),_c('a-descriptions-item',{attrs:{"label":"Taux de pénalité"}},[_vm._v(" 10% ")]),_c('a-descriptions-item',{attrs:{"label":"Eligible à partir de:"}},[_vm._v(" 3 ans ")]),_c('a-descriptions-item',{attrs:{"label":"Garantie de compte pour le prêt"}},[_vm._v(" 300000 Fcfa ")])],1)],1)],1),_c('a-col',{attrs:{"span":12,"md":16}},[_c('a-card',{staticClass:"header-solid h-full",attrs:{"bordered":false,"bodyStyle":{ paddingTop: '16px', paddingBottom: '16px' }}},[_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{staticClass:"mb-24",attrs:{"span":24,"md":24}},[[_c('h6',{staticClass:"font-semibold m-0"},[_vm._v("Parametres généraux")])],_c('a-form',{staticClass:"login-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.form_password,"hideRequiredMark":true},on:{"submit":_vm.passwordSubmit}},[_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":12,"md":12}},[_c('a-form-item',{attrs:{"label":"Taux d'interêt de prêt (%)","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'taux_interet',
                        {
                          initialValue: 0,
                          rules: [
                            {
                              required: true,
                              message: 'Taux de penalite generer incorrect!',
                            } ],
                        } ]),expression:"[\n                        'taux_interet',\n                        {\n                          initialValue: 0,\n                          rules: [\n                            {\n                              required: true,\n                              message: 'Taux de penalite generer incorrect!',\n                            },\n                          ],\n                        },\n                      ]"}],attrs:{"type":"number","placeholder":"Taux d'interêt"}})],1)],1),_c('a-col',{attrs:{"span":12,"md":12}},[_c('a-form-item',{attrs:{"label":"Taux de pénalité de prêt (%)","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'taux_penalite',
                        {
                          initialValue: 0,
                          rules: [
                            {
                              required: true,
                              message: 'Taux de penalite generer incorrect!',
                            } ],
                        } ]),expression:"[\n                        'taux_penalite',\n                        {\n                          initialValue: 0,\n                          rules: [\n                            {\n                              required: true,\n                              message: 'Taux de penalite generer incorrect!',\n                            },\n                          ],\n                        },\n                      ]"}],attrs:{"type":"number","placeholder":"Taux de penalité"}})],1)],1),_c('a-col',{attrs:{"span":12,"md":12}},[_c('a-form-item',{attrs:{"label":"Temps d'eligibilité  (Mois)","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'temps',
                        {
                          initialValue: 0,
                          rules: [
                            {
                              required: true,
                              message:
                                'Temps d\'éligibilité generer incorrect!',
                            } ],
                        } ]),expression:"[\n                        'temps',\n                        {\n                          initialValue: 0,\n                          rules: [\n                            {\n                              required: true,\n                              message:\n                                'Temps d\\'éligibilité generer incorrect!',\n                            },\n                          ],\n                        },\n                      ]"}],attrs:{"type":"number","placeholder":"Taux d'eligibilite"}})],1)],1),_c('a-col',{attrs:{"span":12,"md":12}},[_c('a-form-item',{attrs:{"label":"Garantie du compte (Fcfa)","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'garantie',
                        {
                          initialValue: 0,
                          rules: [
                            {
                              required: true,
                              message: 'Garantie generer incorrect!',
                            } ],
                        } ]),expression:"[\n                        'garantie',\n                        {\n                          initialValue: 0,\n                          rules: [\n                            {\n                              required: true,\n                              message: 'Garantie generer incorrect!',\n                            },\n                          ],\n                        },\n                      ]"}],attrs:{"type":"number","placeholder":"Garantie du compte"}})],1)],1),_c('a-col',{attrs:{"span":12,"md":12}},[_c('a-form-item',{attrs:{"label":"Code secret","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'code_secret',
                        {
                          rules: [
                            {
                              required: true,
                              message: 'Code secret incorrect!',
                            } ],
                        } ]),expression:"[\n                        'code_secret',\n                        {\n                          rules: [\n                            {\n                              required: true,\n                              message: 'Code secret incorrect!',\n                            },\n                          ],\n                        },\n                      ]"}],attrs:{"type":"text","placeholder":"Code secret"}})],1)],1),_c('a-col',{attrs:{"span":24,"md":24}},[_c('div',{staticClass:"mb-4 text-right"},[_c('a-button',{staticClass:"login-form-button",attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" Generer ")])],1)])],1)],1)],2)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }